import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as Config } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DocumentTypeDTO } from '../document-type.model';

@Injectable({ providedIn: 'root' })
export class DocumentTypeService {

  private readonly http = inject(HttpClient);
  private readonly baseUrlPath: string = `${Config.apiBaseUrl}/document-type`;

  public getAll (): Observable<DocumentTypeDTO[]> {
    return this.http.get<DocumentTypeDTO[]>(this.baseUrlPath);
  }

  public getById (id: number): Observable<DocumentTypeDTO> {
    return this.http.get<DocumentTypeDTO>(`${this.baseUrlPath}/${id}`);
  }

  public createDocumentType (documentType: Partial<DocumentTypeDTO>): Observable<DocumentTypeDTO> {
    return this.http.post<DocumentTypeDTO>(`${this.baseUrlPath}`, documentType);
  }

  public updateDocumentType (documentType: DocumentTypeDTO): Observable<DocumentTypeDTO> {
    return this.http.patch<DocumentTypeDTO>(`${this.baseUrlPath}/${documentType.id}`, documentType);
  }

  public deleteDocumentType (id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrlPath}/${id}`);
  }

  public exists (code: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrlPath}/exists`, { params: new HttpParams().appendAll({ code }) });
  }

}
