import { CustomerDTO } from '../customerDTO';
import { createReducer, on } from '@ngrx/store';
import { CustomersApiActions, CustomersDefaultActions } from './customers.action';
import { cloneDeep } from 'lodash-es';
import { Nullable } from '../../../shared/models/types';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { produce } from 'immer';
import { ArrayUtils } from '../../../shared/utility/ArrayUtils';

export interface CustomersState {
  customerList: CustomerDTO[]
  customerListXLoggedOpe: CustomerDTO[]
  isLoading: boolean,
  showModalLink: boolean,
  httpStatus: Nullable<HttpStatus>
}

const customersInitialState: CustomersState = {
  customerList: [],
  customerListXLoggedOpe: [],
  isLoading: false,
  showModalLink: false,
  httpStatus: null
};

const loadingActions = [
  CustomersApiActions.allCustomers, CustomersApiActions.customersForLoggedOperator, CustomersApiActions.createCustomer,
  CustomersApiActions.updateCustomer, CustomersApiActions.deleteCustomer, CustomersApiActions.uploadLogo,
  CustomersApiActions.propagateTdp
];

const failureActions = [
  CustomersApiActions.allCustomersFailure, CustomersApiActions.customersForLoggedOperatorFailure,
  CustomersApiActions.createCustomerFailure, CustomersApiActions.updateCustomerFailure,
  CustomersApiActions.deleteCustomerFailure, CustomersApiActions.uploadLogoFailure,
  CustomersApiActions.propagateTdpFailure
];

export const customersReducer = createReducer(
  customersInitialState,

  on(...loadingActions, (state) => ({ ...state, isLoading: true })),

  on(CustomersDefaultActions.setShowModalLink, (state, { showModalLink }) => ({ ...state, showModalLink })),

  on(CustomersApiActions.allCustomersSuccess, (state, { customerList }) => ({ ...state, customerList: [...customerList], isLoading: false })),

  on(CustomersApiActions.customersForLoggedOperatorSuccess, (state, { customerListXLoggedOpe }) => ({ ...state, customerListXLoggedOpe: [...customerListXLoggedOpe], isLoading: false })),

  on(CustomersApiActions.createCustomerSuccess, (state, { customer, addToVisibleCustomers }) => produce(state, draft => {
    draft.customerList.push(customer);
    if(addToVisibleCustomers){
      draft.customerListXLoggedOpe.push(customer);
    }
    draft.isLoading = false;
  })),

  on(CustomersApiActions.updateCustomerSuccess, (state, { customer }) => produce(state, draft => {
    draft.customerList = ArrayUtils.updateArrayByObjProp(draft.customerList, 'id', customer);
    draft.isLoading = false;
  })),

  on(CustomersApiActions.deleteCustomerSuccess, (state, { id }) => produce(state, draft => {
    draft.customerList = draft.customerList.filter(c => c.id !== id);
    draft.customerListXLoggedOpe = draft.customerListXLoggedOpe.filter(c => c.id !== id);
    draft.isLoading = false;
  })),

  on(CustomersApiActions.uploadLogoSuccess, (state) => ({ ...state, isLoading: false })),

  on(...failureActions, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(CustomersDefaultActions.clearErrors, (state) => ({ ...state, httpStatus: null })),
  on(CustomersDefaultActions.clearState, () => ({ ...customersInitialState }))
);
