import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as Config } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { DatoPersonalizzato, TdpPropagationDTO } from "../dato-personalizzato.model";
import { Nullable } from "../../../shared/models/types";

@Injectable({
  providedIn: 'root'
})
export class DatiPersonalizzatiService {

  private readonly http = inject(HttpClient);
  protected readonly basePath = `${Config.apiBaseUrl}/datipers`;

  public propagate (body: TdpPropagationDTO): Observable<Nullable<DatoPersonalizzato[]>> {
    return this.http.post<Nullable<DatoPersonalizzato[]>>(`${this.basePath}/import-propagate`, body);
  }

}